import activitiesAndSubactivities from './activities-and-subactivities'
import catalogueManagement from './catalogue-management'
import subscriptionAndPackages from './subscription-and-packages'
import deviceMarketplace from './device-marketplace'
import packages from './packages'
import devicesMarketplace from './devices-marketplace'

export default [
  ...activitiesAndSubactivities,
  ...catalogueManagement,
  ...subscriptionAndPackages,
  ...deviceMarketplace,
  ...packages,
  ...devicesMarketplace,
]
