export default [
  // *===============================================---*
  // *--------- Activity & Sub Activity ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/general-settings/activities/list',
    name: 'general-settings-activities-list',
    component: () => import('@/views/general-settings/activities-and-subactivities/activities-list/ActivitiesList.vue'),
    meta: {
      resource: 'Activity & Sub Activity',
      action: 'V',
      pageTitle: 'Activities',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Activity & SubActivity',
        },
        {
          text: 'Activities',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/activities/create',
    name: 'general-settings-activities-create',
    component: () => import('@/views/general-settings/activities-and-subactivities/activities-create/ActivitiesCreate.vue'),
    meta: {
      resource: 'Activity & Sub Activity',
      action: 'C',
      pageTitle: 'Create Activity / Sub Activity',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Activity & SubActivity',
        },
        {
          text: 'Activities',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/activities/edit/:id',
    name: 'general-settings-activities-edit',
    component: () => import('@/views/general-settings/activities-and-subactivities/activities-edit/ActivitiesEdit.vue'),
    meta: {
      resource: 'Activity & Sub Activity',
      action: 'E',
      pageTitle: 'Edit Activity',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Activity & SubActivity',
        },
        {
          text: 'Activities',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/subactivities/edit/:id',
    name: 'general-settings-subactivities-edit',
    component: () => import('@/views/general-settings/activities-and-subactivities/activities-edit/subactivities-edit/SubActivitiesEdit.vue'),
    meta: {
      resource: 'Activity & Sub Activity',
      action: 'E',
      pageTitle: 'Edit Sub Activity',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Activity & SubActivity',
        },
        {
          text: 'Sub Activities',
          active: true,
        },
      ],
    },
  },
]
