// *===============================================---*
// *--------- AUTHENTICATION ---- ---------------------------------------*
// *===============================================---*

//  AUTH SERVICES
export const loginAPI = '/v1/login'
export const logoutAPI = '/v1/logout'
export const resetPinAPI = '/v1/resetPin'

// *===============================================---*
// *--------- SYSTEM SETTINGS ---- ---------------------------------------*
// *===============================================---*

//  SYSTEM CODES SERVICES
export const fetchSystemCodesAPI = '/v1/fetch/sys-codes'
export const fetchSystemCodeAPI = '/v1/fetch/sys-code'
export const procSystemCodesAPI = '/v1/proc/sys-code'

//  MODULES & MENUS SERVICES
export const fetchModulesAPI = '/v1/fetch/modules'
export const fetchModuleAPI = '/v1/fetch/module'
export const procModuleAPI = '/v1/proc/module'

export const fetchMenusAPI = '/v1/fetch/module-menus'
export const fetchMenuAPI = '/v1/fetch/menu'
export const procMenuAPI = '/v1/proc/menu'

//  USERS & PRIVILEGES SERVICES
export const fetchUsersAPI = '/v1/fetch/users'
export const fetchUserAPI = '/v1/fetch/user'
export const procUserAPI = '/v1/proc/user'

export const fetchPrivilegesAPI = '/v1/fetch/privileges'
export const fetchPrivilegeAPI = '/v1/fetch/privilege'
export const procPrivilegeAPI = '/v1/proc/privilege'

// *===============================================---*
// *--------- GENERAL SETTINGS ---- ---------------------------------------*
// *===============================================---*

//  ACTIVITY & SUBACTIVITY
export const fetchActivitiesAPI = '/v1/config/activity/fetch'
export const createActivityAPI = '/v1/config/activity/create'
export const updateActivityAPI = '/v1/config/activity/update'
export const deleteActivityAPI = '/v1/config/activity/delete'

export const fetchSubActivityAPI = '/v1/config/subactivity/fetch'
export const createSubActivityAPI = '/v1/config/subactivity/create'
export const updateSubActivityAPI = '/v1/config/subactivity/update'
export const deleteSubActivityAPI = '/v1/config/subactivity/delete'

//  CATALOGUE MANAGEMENT
export const uploadMasterCatalogueAPI = '/v1/proc/master-product'

// *===============================================---*
// *--------- RETAILERS MANAGEMENT ---- ---------------------------------------*
// *===============================================---*

//  RETAILERS & BRANCHES
export const fetchRetailersAPI = '/v1/entity/fetch'
export const updateRetailerAPI = '/v1/entity/update'

export const fetchBranchesAPI = '/v1/entity/branches/fetch'
export const fetchBranchAPI = '/v1/branch/fetch'
export const updateBranchAPI = '/v1/branch/update'
export const procBranchAPI = '/v1/proc/branch'

//  CASHIERS
export const fetchCashierAPI = '/v1/cashier'
export const updateCashierAPI = '/v1/cashier/update'

//  SUBSCRIPTIONS
export const fetchRetailerSubscriptionAPI = '/v1/fetch/subscription'
export const updateRetailerSubscriptionAPI = '/v1/proc/subscription'

//  DEVICES
export const manageRetailerDeviceAPI = '/v1/branch/update-products'

//  RETAILER CATALOGUE MANAGEMENT

//  CATEGORY & SUB CATEGORY
export const fetchCategoriesAPI = '/v1/catalog/category/fetch'
export const procCategoryAPI = '/v1/catalog/category/proc'

export const procSubCategoryAPI = '/v1/catalog/subcategory/proc'

//  CATALOGUE MANAGEMENT
export const fetchRetailerCatalogueAPI = '/v1/catalog/fetch'
export const validateRetailerCatalogueAPI = '/v1/catalog/upload-grid/validate'
export const uploadRetailerCatalogueAPI = '/v1/proc/product'

// *===============================================---*
// *--------- SALES ---- ---------------------------------------*
// *===============================================---*

//  REPORTS SERVICES
export const subscriptionsOverviewAPI = '/v1/fetch/reports/subscriptions-overview'
export const subscriptionsStoreTypesAPI = '/v1/fetch/reports/subscriptions-storetypes'
export const subscriptionsDepartmentsAPI = '/v1/fetch/reports/subscriptions-departments'
export const subscriptionsAgentTypesAPI = '/v1/fetch/reports/subscriptions-agenttypes'
export const subscriptionsCitiesAPI = '/v1/fetch/reports/subscriptions-cities'
export const subscriptionsInvoiceAPI = '/v1/fetch/sales_invoice'
export const subscriptionsPackagesAPI = '/v1/fetch/reports/subscriptions-packages'
export const subscriptionsUserTypesAPI = '/v1/fetch/reports/subscriptions-usertypes'
export const subscriptionsExportAPI = '/v1/fetch/reports/subscriptions-export'

//  SALES TARGETS
export const createSalesTargetAPI = '/v1/sales-target/create'
export const updateSalesTargetAPI = '/v1/sales-target/update'
export const deactivateSalesTargetAPI = '/v1/sales-target/deactivate'
export const fetchSalesTargetsAPI = '/v1/sales-target'
export const fetchSalesTargetAPI = '/v1/sales-target'

//  POS SALES
export const posInvoiceAPI = '/v1/pos/invoice-data'

// PACKAGES & SUBSCRIPTIONS
export const fetchPackagesAPI = '/v1/fetch/packages'
export const fetchPackageAPI = '/v1/fetch/package'
export const createPackageAPI = '/v1/package/create'
export const updatePackageAPI = '/v1/package/update'

//  Devices Marketplace
export const fetchMarketplaceProductsAPI = '/v1/fetch/marketplace-products'
export const fetchMarketplaceProductAPI = '/v1/fetch/marketplace-product'
export const createMarketplaceProductAPI = '/v1/marketplace-product/create'
export const updateMarketplaceProductAPI = '/v1/marketplace-product/update'

export const fetchDevices = '/v1/fetch/peripherals'
export const createDevices = '/v1/marketplace-product/create'
