import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    navMenuItems: JSON.parse(localStorage.getItem('menus')) || [],
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    UPDATE_NAV_MENU_ITEMS(state, menus) {
      localStorage.setItem('menus', JSON.stringify(menus))
      state.navMenuItems = menus
    },
  },
  actions: {},
}
