export default [
  // *===============================================---*
  // *--------- System Codes ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/system-settings/system-codes/list',
    name: 'system-settings-system-codes-list',
    component: () => import('@/views/system-settings/system-codes/system-codes-list/SystemCodesList.vue'),
    meta: {
      resource: 'System Codes',
      action: 'V',
      pageTitle: 'System Codes',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'System Codes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-settings/system-codes/create',
    name: 'system-settings-system-codes-create',
    component: () => import('@/views/system-settings/system-codes/system-codes-create/SystemCodesCreate.vue'),
    meta: {
      resource: 'System Codes',
      action: 'C',
      pageTitle: 'Add System Codes',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'System Codes',
          active: true,
        },
      ],
    },
  },
]
