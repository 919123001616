export default [
  // *===============================================---*
  // *--------- Privileges ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/system-settings/privileges/list',
    name: 'system-settings-privileges-list',
    component: () => import('@/views/system-settings/privileges/privileges-list/PrivilegesList.vue'),
    meta: {
      resource: 'Roles & Privileges',
      action: 'V',
      pageTitle: 'Roles & Privileges',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Users & Privileges',
        },
        {
          text: 'Roles & Privileges',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-settings/privileges/create',
    name: 'system-settings-privilege-create',
    component: () => import('@/views/system-settings/privileges/privileges-create/PrivilegesCreate.vue'),
    meta: {
      resource: 'Roles & Privileges',
      action: 'C',
      pageTitle: 'Add Privileges',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Users & Privileges',
        },
        {
          text: 'Roles & Privileges',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-settings/privilege/edit/:id',
    name: 'system-settings-privilege-edit',
    component: () => import('@/views/system-settings/privileges/privileges-edit/PrivilegesEdit.vue'),
    meta: {
      resource: 'Roles & Privileges',
      action: 'E',
      pageTitle: 'Edit Privileges',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Users & Privileges',
        },
        {
          text: 'Roles & Privileges',
          active: true,
        },
      ],
    },
  },
]
