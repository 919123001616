export default [
  {
    path: '/dashboard/sales',
    name: 'dashboard-sales',
    component: () => import('@/views/dashboard/sales/Sales.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'V',
    },
  },
]
