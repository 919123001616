import Vue from 'vue'
import Toast from 'vue-toastification'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */

// Prevents toasts of the same type from appearing simultaneously, discarding duplicates
const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter(t => t.type === toast.type).length !== 0) {
    // Returning false discards the toast
    return false
  }
  // You can modify the toast if you want
  return toast
}

const filterToasts = toasts => {
  // Keep track of existing types
  const types = {}
  return toasts.reduce((aggToasts, toast) => {
    // Check if type was not seen before
    if (!types[toast.type]) {
      aggToasts.push(toast)
      types[toast.type] = true
    }
    return aggToasts
  }, [])
}

Vue.use(Toast, {
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
  // filterBeforeCreate,
  filterToasts,
})
