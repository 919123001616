export default [
  // *===============================================---*
  // *--------- Subscription & Packages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/general-settings/subscriptionPackage/list',
    name: 'general-settings-subscriptionPackage-list',
    component: () => import('@/views/general-settings/subscription-and-packages/subscriptionPackage-list/SubscriptionPackageList.vue'),
    meta: {
      resource: 'Subscriptions & Packages',
      action: 'V',
      pageTitle: 'Subscription & Packages',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Subscription & Packages',
        },
        {
          text: 'Packages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/subscriptionPackage/create',
    name: 'general-settings-subscriptionPackage-create',
    component: () => import('@/views/general-settings/subscription-and-packages/package-create/PackageCreate.vue'),
    meta: {
      resource: 'Subscriptions & Packages',
      action: 'C',
      pageTitle: 'Create Package',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Subscription and Packages',
        },
        {
          text: 'Packages',
          active: true,
        },
      ],
    },
  },
]
