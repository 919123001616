<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        :variant="variant"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <feather-icon
          :icon="icon"
          size="15"
        />
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div>
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-${variant}`"
            v-text="title"
          />
          <small
            v-if="text"
            class="d-inline-block text-body"
            v-text="text"
          />
        </div>

        <span
          class="cursor-pointer toastification-close-icon ml-auto "
          @click="$emit('close-toast')"
        >
          <feather-icon
            v-if="!hideClose"
            icon="XIcon"
            class="text-body"
          />
        </span>
      </div>
    </div>

    <!-- Progress Bar -->
    <b-row class="mt-1">
      <b-col class="p-0">
        <b-progress
          v-model="getUploadPercent"
          max="100"
          :class="`progress-bar-${variant}`"
          class="rounded-0"
          :variant="variant"
          :animated="getUploadPercent < 100"
        />
        <small
          class="text-primary float-right text-small"
          v-text="`${getUploadPercent}%`"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar, BProgress, BRow, BCol,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BAvatar,
    BProgress,
    BRow,
    BCol,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getUploadPercent() {
      return store.getters['appLoaderConfig/uploadPercent']
    },
  },
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}

.text-small {
  font-size: 10px;
}
</style>
