export default [
  // *===============================================---*
  // *--------- Subscription & Packages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/general-settings/deviceMarketPlace/list',
    name: 'general-settings-deviceMarketPlace-list',
    component: () => import('@/views/general-settings/marketplace/marketplace-list/MarketPlaceList.vue'),
    meta: {
      resource: 'Device Marketplace',
      action: 'V',
      pageTitle: 'Device Marketplace',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Device & MarketPlace',
        },
        {
          text: 'Market Place',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/deviceMarketPlace/create',
    name: 'general-settings-deviceMarketPlace-create',
    component: () => import('@/views/general-settings/marketplace/marketPlace-create/MarketPlaceCreate.vue'),
    meta: {
      resource: 'Device Marketplace',
      action: 'C',
      pageTitle: 'Create Device',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Device Market Place',
        },
        {
          text: 'Devices',
          active: true,
        },
      ],
    },
  },
]
