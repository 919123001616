export default [
  // *===============================================---*
  // *--------- Reports ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/sales/subscriptions/reports/subscriptions-overview',
    name: 'sales-reports-subscriptions-overview',
    component: () => import('@/views/sales/subscriptions/reports/subscriptions-overview/SubscriptionsOverviewList.vue'),
    meta: {
      resource: 'Subscriptions Overview',
      action: 'V',
      pageTitle: 'Subscriptions Overview',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Subscriptions',
        },
        {
          text: 'Reports',
        },
        {
          text: 'Subscriptions Overview',
          active: true,
        },
      ],
    },
  },
]
