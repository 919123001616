export default [
  // *===============================================---*
  // *--------- Catalogue Management ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/retailers-management/catalogue-management/retailer-catalogue/list',
    name: 'retailers-management-catalogue-management-retailer-catalogue-list',
    component: () => import('@/views/retailers-management/retailers/retailers-list/RetailersList.vue'),
    meta: {
      resource: 'Retailer Catalogue Management',
      action: 'V',
      pageTitle: 'Retailer Catalogue',
      breadcrumb: [
        {
          text: 'Retailers Management',
        },
        {
          text: 'Retailers & Branches',
        },
        {
          text: 'Catalogue Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retailers-management/catalogue-management/retailer-catalogue/create/:id',
    name: 'retailers-management-catalogue-management-retailer-catalogue-create',
    component: () => import('@/views/retailers-management/catalogue-management/retailer-catalogue/retailer-catalogue-create/RetailerCatalogueCreate.vue'),
    meta: {
      resource: 'Retailer Catalogue Management',
      action: 'C',
      pageTitle: 'Retailer Catalogue',
      breadcrumb: [
        {
          text: 'Retailers Management',
        },
        {
          text: 'Retailers & Branches',
        },
        {
          text: 'Catalogue Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retailers-management/catalogue-management/retailer-catalogue/view/:id',
    name: 'retailers-management-catalogue-management-retailer-catalogue-view',
    component: () => import('@/views/retailers-management/catalogue-management/retailer-catalogue/retailer-catalogue-table/RetailerCatalogueTable.vue'),
    meta: {
      resource: 'Retailer Catalogue Management',
      action: 'V',
      pageTitle: 'Retailer Catalogue',
      breadcrumb: [
        {
          text: 'Retailers Management',
        },
        {
          text: 'Retailers & Branches',
        },
        {
          text: 'Catalogue Management',
          active: true,
        },
      ],
    },
  },
]
