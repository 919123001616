export default [
  // *===============================================---*
  // *--------- Invoice ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/sales/subscriptions/invoice/:id',
    name: 'sales-subscriptions-invoice',
    component: () => import('@/views/sales/subscriptions/invoice/SubscriptionsInvoice.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
