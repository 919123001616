export default {
  namespaced: true,
  state: {
    uploadPercent: 0,
  },
  getters: {
    uploadPercent(state) {
      return state.uploadPercent
    },
  },
  mutations: {
    SET_UPLOAD_PERCENT(state, data) {
      state.uploadPercent = data
    },
  },
  actions: {},
}
