export default [
  // *===============================================---*
  // *--------- Modules ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/system-settings/modules/list',
    name: 'system-settings-modules-list',
    component: () => import('@/views/system-settings/modules-and-menus/modules/modules-list/ModulesList.vue'),
    meta: {
      resource: 'Modules & Menus',
      action: 'V',
      pageTitle: 'Modules',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Modules & Menus',
        },
        {
          text: 'Modules',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-settings/modules/view/:id',
    name: 'system-settings-modules-view',
    component: () => import('@/views/system-settings/modules-and-menus/modules/modules-view/ModulesView.vue'),
    meta: {
      resource: 'Modules & Menus',
      action: 'V',
      pageTitle: 'View Module',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Modules & Menus',
        },
        {
          text: 'Modules',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-settings/modules/edit/:id',
    name: 'system-settings-modules-edit',
    component: () => import('@/views/system-settings/modules-and-menus/modules/modules-edit/ModulesEdit.vue'),
    meta: {
      resource: 'Modules & Menus',
      action: 'E',
      pageTitle: 'Edit Module',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Modules & Menus',
        },
        {
          text: 'Modules',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-settings/modules/create',
    name: 'system-settings-modules-create',
    component: () => import('@/views/system-settings/modules-and-menus/modules/modules-create/ModulesCreate.vue'),
    meta: {
      resource: 'Modules & Menus',
      action: 'C',
      pageTitle: 'Add Modules',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Modules & Menus',
        },
        {
          text: 'Modules',
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- Menus ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/system-settings/menus/create',
    name: 'system-settings-menus-create',
    component: () => import('@/views/system-settings/modules-and-menus/menus/menus-create/MenusCreate.vue'),
    meta: {
      resource: 'Modules & Menus',
      action: 'C',
      pageTitle: 'Add Menus',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Modules & Menus',
        },
        {
          text: 'Menus',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-settings/menus/edit/:id',
    name: 'system-settings-menus-edit',
    component: () => import('@/views/system-settings/modules-and-menus/menus/menus-edit/MenusEdit.vue'),
    meta: {
      resource: 'Modules & Menus',
      action: 'E',
      pageTitle: 'Edit Menus',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Modules & Menus',
        },
        {
          text: 'Menus',
          active: true,
        },
      ],
    },
  },
]
