export default [
  {
    path: '/general-settings/catalogue-management/master-catalogue/create',
    name: 'general-settings-catalogue-management-master-catalogue-create',
    component: () => import('@/views/general-settings/catalogue-management/master-catalogue/master-catalogue-create/MasterCatalogueCreate.vue'),
    meta: {
      resource: 'Master Catalogue',
      action: 'C',
      pageTitle: 'Master Catalogue',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Catalogue Management',
        },
        {
          text: 'Master Catalogue',
          active: true,
        },
      ],
    },
  },
]
