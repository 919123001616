export default [
  // *===============================================---*
  // *--------- Packages ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/general-settings/packages/list',
    name: 'general-settings-packages-list',
    component: () => import('@/views/general-settings/packages/packages-list/PackagesList.vue'),
    meta: {
      resource: 'Packages & Subscriptions',
      action: 'V',
      pageTitle: 'Packages & Subscriptions',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Packages & Subscriptions',
        },
        {
          text: 'Packages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/packages/create',
    name: 'general-settings-packages-create',
    component: () => import('@/views/general-settings/packages/packages-create/PackagesCreate.vue'),
    meta: {
      resource: 'Packages & Subscriptions',
      action: 'C',
      pageTitle: 'Add Packages',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Packages & Subscriptions',
        },
        {
          text: 'Packages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/packages/edit/:id',
    name: 'general-settings-packages-edit',
    component: () => import('@/views/general-settings/packages/packages-edit/PackagesEdit.vue'),
    meta: {
      resource: 'Packages & Subscriptions',
      action: 'E',
      pageTitle: 'Edit Packages',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Packages & Subscriptions',
        },
        {
          text: 'Packages',
          active: true,
        },
      ],
    },
  },
]
