export default [
  // *===============================================---*
  // *--------- Subscriptions ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/retailers-management/retailer/subscriptions/edit/:id',
    name: 'retailers-management-retailer-subscriptions-edit',
    component: () => import('@/views/retailers-management/retailers/subscriptions/subscriptions-edit/SubscriptionsEdit.vue'),
    meta: {
      resource: 'Retailers & Branches',
      action: 'E',
      pageTitle: 'Edit Subscription',
      breadcrumb: [
        {
          text: 'Retailers Management',
        },
        {
          text: 'Retailers & Branches',
        },
        {
          text: 'Subscriptions',
          active: true,
        },
      ],
    },
  },
]
