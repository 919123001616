import VueCookies from 'vue-cookies'
import { loginAPI, logoutAPI, resetPinAPI } from '@/services/apis'

export default {
  namespaced: true,
  state: {
    token: VueCookies.get('token') || '',
    isAuthenticated: VueCookies.get('isAuthenticated') || false,
    userDetails: VueCookies.get('userDetails') || '',
    userAbilities: JSON.parse(VueCookies.get('userAbilities')) || [{ subject: 'Auth', action: 'read' }],
    userCodems: JSON.parse(localStorage.getItem('userCodems')) || [],
  },

  getters: {
    isLoggedIn(state) {
      return state.token && state.userDetails && state.isAuthenticated
    },

    authToken(state) {
      return state.token ? `Bearer ${state.token}` : ''
    },

    getUserDetails(state) {
      return state.userDetails
    },
  },

  mutations: {
    SET_LOGIN_DETAILS(state, { user, abilities, codems }) {
      const { token, ...rest } = user

      // Store in Cookies
      window.$cookies.set('token', token)
      window.$cookies.set('isAuthenticated', true)
      window.$cookies.set('userDetails', JSON.stringify(rest))
      window.$cookies.set('userAbilities', JSON.stringify(abilities))

      localStorage.setItem('userCodems', JSON.stringify(codems))

      // Update State
      state.token = token
      state.isAuthenticated = true
      state.userDetails = rest
    },

    UNSET_LOGIN_DETAILS(state) {
      // Remove Cookies
      window.$cookies.remove('token')
      window.$cookies.remove('isAuthenticated')
      window.$cookies.remove('userDetails')
      window.$cookies.remove('userAbilities')

      localStorage.clear()

      // Update State
      state.token = ''
      state.isAuthenticated = false
      state.userDetails = ''
    },
  },

  actions: {
    async login(ctx, loginParams) {
      try {
        const response = await this.$axios.post(loginAPI, loginParams, {
          headers: { 'Accept-Language': 'en' },
        })

        const data = response.data.data[0]
        const {
          abilities,
          user,
          menus,
          codems,
        } = data

        // const { user, menus } = data

        // const abilities = [{ action: 'manage', subject: 'all' }]

        //  Update User Login Details & abilities
        await ctx.commit('SET_LOGIN_DETAILS', { user, abilities, codems })

        //  Update Menus
        ctx.commit('verticalMenu/UPDATE_NAV_MENU_ITEMS', menus, { root: true })

        return response
      } catch (error) {
        return error
      }
    },

    async logout(ctx) {
      try {
        const response = await this.$axios.get(logoutAPI)

        await ctx.commit('UNSET_LOGIN_DETAILS')

        return response
      } catch (error) {
        return error
      }
    },

    async resetPin(_, resetParams) {
      try {
        const response = await this.$axios.post(resetPinAPI, resetParams)

        return response
      } catch (error) {
        return error
      }
    },
  },
}
