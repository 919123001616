export default [
  // *===============================================---*
  // *--------- Branches ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/retailers-management/retailer/branches/edit/:id',
    name: 'retailers-management-retailer-branches-edit',
    component: () => import('@/views/retailers-management/retailers/branches/branches-edit/BranchesEdit.vue'),
    meta: {
      resource: 'Retailers & Branches',
      action: 'E',
      pageTitle: 'Edit Branch',
      breadcrumb: [
        {
          text: 'Retailers Management',
        },
        {
          text: 'Retailers & Branches',
        },
        {
          text: 'Branches',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retailers-management/retailer/branches/view/:id',
    name: 'retailers-management-retailer-branches-view',
    component: () => import('@/views/retailers-management/retailers/branches/branches-view/BranchesView.vue'),
    meta: {
      resource: 'Retailers & Branches',
      action: 'V',
      pageTitle: 'View Branch',
      breadcrumb: [
        {
          text: 'Retailers Management',
        },
        {
          text: 'Retailers & Branches',
        },
        {
          text: 'Branches',
          active: true,
        },
      ],
    },
  },
]
