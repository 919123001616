import privileges from './privileges'
import users from './users'
import modulesAndMenus from './modules-and-menus'
import systemCodes from './system-codes'

export default [
  ...privileges,
  ...users,
  ...modulesAndMenus,
  ...systemCodes,
]
