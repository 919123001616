import retailers from './retailers'
import branches from './branches'
import cashiers from './cashiers'
import subscriptions from './subscriptions'
import catalogueManagement from './catalogue-management'

export default [
  ...retailers,
  ...branches,
  ...cashiers,
  ...subscriptions,
  ...catalogueManagement,
]
