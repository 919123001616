export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/system-settings/users/list',
    name: 'system-settings-users-list',
    component: () => import('@/views/system-settings/users/users-list/UsersList.vue'),
    meta: {
      resource: 'Users',
      action: 'V',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Users & Privileges',
        },
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-settings/users/view/:id',
    name: 'system-settings-users-view',
    component: () => import('@/views/system-settings/users/users-view/UsersView.vue'),
    meta: {
      resource: 'Users',
      action: 'V',
      pageTitle: 'View User',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Users & Privileges',
        },
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-settings/users/edit/:id',
    name: 'system-settings-users-edit',
    component: () => import('@/views/system-settings/users/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'Users',
      action: 'E',
      pageTitle: 'Edit User',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Users & Privileges',
        },
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-settings/users/create/',
    name: 'system-settings-users-create',
    component: () => import('@/views/system-settings/users/users-create/UsersCreate.vue'),
    meta: {
      resource: 'Users',
      action: 'C',
      pageTitle: 'Add User',
      breadcrumb: [
        {
          text: 'System Settings',
        },
        {
          text: 'Users & Privileges',
        },
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
]
