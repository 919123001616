export default [
  // *===============================================---*
  // *--------- Invoice ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/sales/pos/invoice',
    name: 'sales-pos-invoice',
    props: route => ({ query: route.query.data }),
    component: () => import('@/views/sales/pos/invoice/PosInvoice.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
