import { fetchSystemCodeAPI, fetchSystemCodesAPI, procSystemCodesAPI } from '@/services/apis'

export default {
  namespaced: true,
  state: {
    statusOptions: JSON.parse(localStorage.getItem('statusOptions')) || [],
    sStatusOptions: JSON.parse(localStorage.getItem('sStatusOptions')) || [],
    userTypeOptions: JSON.parse(localStorage.getItem('userTypeOptions')) || [],
    accountTypeOptions: JSON.parse(localStorage.getItem('accountTypeOptions')) || [],
    operationOptions: JSON.parse(localStorage.getItem('operationOptions')) || [],
    operationColumns: [],
    tenureOptions: JSON.parse(localStorage.getItem('tenureOptions')) || [],
    paymentMethodOptions: JSON.parse(localStorage.getItem('paymentMethodOptions')) || [],
    paymentStatusOptions: JSON.parse(localStorage.getItem('paymentStatusOptions')) || [],
    salesTargetStatusOptions: JSON.parse(localStorage.getItem('salesTargetStatusOptions')) || [],
    systemCode: null,
  },
  getters: {
    statusOptions() {
      return JSON.parse(localStorage.getItem('statusOptions')) || []
    },
    sStatusOptions() {
      return JSON.parse(localStorage.getItem('sStatusOptions')) || []
    },
    userTypeOptions() {
      return JSON.parse(localStorage.getItem('userTypeOptions')) || []
    },
    accountTypeOptions() {
      return JSON.parse(localStorage.getItem('accountTypeOptions')) || []
    },
    operationOptions() {
      return JSON.parse(localStorage.getItem('operationOptions')) || []
    },
    operationColumns(state, getters) {
      if (getters.operationOptions.length <= 0) {
        return []
      }
      return getters.operationOptions.map(obj => ({
        key: obj.value,
        sortable: false,
        label: obj.label,
        tdClass: 'text-center',
      }))
    },
    tenureOptions() {
      return JSON.parse(localStorage.getItem('tenureOptions')) || []
    },
    paymentMethodOptions() {
      return JSON.parse(localStorage.getItem('paymentMethodOptions')) || []
    },
    paymentStatusOptions() {
      return JSON.parse(localStorage.getItem('paymentStatusOptions')) || []
    },
    salesTargetStatusOptions() {
      return JSON.parse(localStorage.getItem('salesTargetStatusOptions')) || []
    },
  },
  mutations: {
    SET_SYSTEM_CODE(state, { data, tag }) {
      let options
      switch (tag) {
        case 'STS':
          if (data.length <= 0) {
            localStorage.setItem('statusOptions', JSON.stringify([]))
          }
          options = data.map(obj => ({
            label: obj.description,
            value: obj.code,
          }))
          localStorage.setItem('statusOptions', JSON.stringify(options))
          break

        case 'SSTS':
          if (data.length <= 0) {
            localStorage.setItem('sStatusOptions', JSON.stringify([]))
          }
          options = data.map(obj => ({
            label: obj.description,
            value: obj.code,
          }))
          localStorage.setItem('sStatusOptions', JSON.stringify(options))
          break

        case 'UTP':
          if (data.length <= 0) {
            localStorage.setItem('userTypeOptions', JSON.stringify([]))
          }
          options = data.map(obj => ({
            label: obj.description,
            value: obj.code,
          }))
          localStorage.setItem('userTypeOptions', JSON.stringify(options))

          // state.userTypeOptions = data
          break

        case 'ATYP':
          if (data.length <= 0) {
            localStorage.setItem('accountTypeOptions', JSON.stringify([]))
          }
          options = data.map(obj => ({
            label: obj.description,
            value: obj.code,
          }))
          localStorage.setItem('accountTypeOptions', JSON.stringify(options))

          // state.accountTypeOptions = data
          break

        case 'OPR':
          if (data.length <= 0) {
            localStorage.setItem('operationOptions', JSON.stringify([]))
          }
          options = data.map(obj => ({
            label: obj.description,
            value: obj.code,
          }))

          localStorage.setItem('operationOptions', JSON.stringify(options))

          // state.operationOptions = data
          break

        case 'TNR':
          if (data.length <= 0) {
            localStorage.setItem('tenureOptions', JSON.stringify([]))
          }
          options = data.map(obj => ({
            label: obj.description,
            value: obj.code,
          }))
          localStorage.setItem('tenureOptions', JSON.stringify(options))

          // state.tenureOptions = data
          break

        case 'PMETHOD':
          if (data.length <= 0) {
            localStorage.setItem('paymentMethodOptions', JSON.stringify([]))
          }
          options = data.map(obj => ({
            label: obj.description,
            value: obj.code,
          }))
          localStorage.setItem('paymentMethodOptions', JSON.stringify(options))

          // state.paymentMethodOptions = data
          break

        case 'PSTS':
          if (data.length <= 0) {
            localStorage.setItem('paymentStatusOptions', JSON.stringify([]))
          }
          options = data.map(obj => ({
            label: obj.description,
            value: obj.code,
          }))
          localStorage.setItem('paymentStatusOptions', JSON.stringify(options))

          // state.paymentStatusOptions = data
          break

        case 'TSTS':
          if (data.length <= 0) {
            localStorage.setItem('salesTargetStatusOptions', JSON.stringify([]))
          }
          options = data.map(obj => ({
            label: obj.description,
            value: obj.code,
          }))
          localStorage.setItem('salesTargetStatusOptions', JSON.stringify(options))

          // state.salesTargetStatusOptions = data
          break

        default: state.systemCode = data
          break
      }
    },
  },
  actions: {
    async fetchSystemCodes(ctx, queryParams) {
      try {
        const response = await this.$axios.get(fetchSystemCodesAPI, { params: queryParams })
        return response
      } catch (error) {
        return error
      }
    },
    async fetchSystemCode(ctx, tag) {
      try {
        const userCodems = JSON.parse(localStorage.getItem('userCodems'))
        const systemCode = userCodems.filter(obj => obj.tag === tag)

        if (systemCode && systemCode.length > 0) {
          ctx.commit('SET_SYSTEM_CODE', { data: systemCode, tag })
          return true
        }

        const response = await this.$axios.get(`${fetchSystemCodeAPI}/${tag}`)
        const { data } = response.data
        ctx.commit('SET_SYSTEM_CODE', { data, tag })
        return response
      } catch (error) {
        return error
      }
    },
    async procSystemCodes(ctx, params) {
      try {
        const response = await this.$axios.post(procSystemCodesAPI, params)
        return response
      } catch (error) {
        return error
      }
    },
  },
}
