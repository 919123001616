import Vue from 'vue'

// axios
import axios from 'axios'

import { computed } from '@vue/composition-api'

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToastificationContentWithProgressBar from '@/components/toastification/ToastificationContentWithProgressBar.vue'

// Router
import router from '@/router'
import store from '@/store'

const axiosIns = axios.create({
  // You can add your headers here
  baseURL: process.env.VUE_APP_API_URL,
})

// Request interceptor
axiosIns.interceptors.request.use(config => {
  const isLoggedIn = computed(() => store.getters['app-authentication/isLoggedIn']).value
  const token = computed(() => store.getters['app-authentication/authToken']).value

  if (isLoggedIn) {
    config.headers.common.Authorization = token
  }
  if (config.onUploadProgress) {
    store.commit('appLoaderConfig/SET_UPLOAD_PERCENT', 0, { root: true })
    Vue.$toast({
      component: ToastificationContentWithProgressBar,
      props: {
        title: 'Upload',
        icon: 'UploadCloudIcon',
        variant: 'primary',
      },
    },
    {
      id: 'upload-toast',
      timeout: false,
    })
  }

  return config
}, error => {
  Promise.reject(error)
})

// Response interceptor
axiosIns.interceptors.response.use(response => {
  //  If upload progress is present then dismiss the toast once response is received
  if (response.config.onUploadProgress) {
    Vue.$toast.dismiss('upload-toast')
  }

  /* Any status code that lie within the range of 2xx cause this function to trigger */
  if (response?.data?.status) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        // TODO: translate
        title: 'YASSS!',
        icon: 'CheckCircleIcon',
        variant: 'success',
        text: response?.data?.message,
      },
    })
  }

  if (response.statusText && !response?.data?.status) {
    Vue.$toast({
      component: ToastificationContent,
      props: {
        // TODO: translate
        title: 'OOOPS!',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        text: response?.data?.message,
      },
    })
  }

  return response
}, error => {
  //  If upload progress is present then dismiss the toast once error is received
  Vue.$toast.dismiss('upload-toast')

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  switch (error?.response?.status) {
    // BAD REQUEST
    case 400:
      Vue.$toast({
        component: ToastificationContent,
        props: {
          // TODO: translate
          title: 'OOOPS!',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
      break

    // UNAUTHORIZED
    case 401:
      Vue.$toast({
        component: ToastificationContent,
        props: {
          // TODO: translate
          title: 'OOOPS!',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: error?.response?.statusText,
        },
      })

      store.commit('app-authentication/UNSET_LOGIN_DETAILS')

      if (router.currentRoute.name !== 'auth-login') {
        router.push({ name: 'auth-login' })
      }
      break

    default:
      Vue.$toast({
        component: ToastificationContent,
        props: {
          // TODO: translate
          title: 'OOOPS!',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: error?.response?.statusText,
        },
      })
      break
  }

  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
