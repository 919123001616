export default [
  // *===============================================---*
  // *--------- Retailers ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/retailers-management/retailers/list',
    name: 'retailers-management-retailers-list',
    component: () => import('@/views/retailers-management/retailers/retailers-list/RetailersList.vue'),
    meta: {
      resource: 'Retailers & Branches',
      action: 'V',
      pageTitle: 'Retailers',
      breadcrumb: [
        {
          text: 'Retailers Management',
        },
        {
          text: 'Retailers & Branches',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retailers-management/retailers/edit/:id',
    name: 'retailers-management-retailers-edit',
    component: () => import('@/views/retailers-management/retailers/retailers-edit/RetailersEdit.vue'),
    meta: {
      resource: 'Retailers & Branches',
      action: 'E',
      pageTitle: 'Edit Retailer',
      breadcrumb: [
        {
          text: 'Retailers Management',
        },
        {
          text: 'Retailers & Branches',
        },
        {
          text: 'Retailers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retailers-management/retailers/view/:id',
    name: 'retailers-management-retailers-view',
    component: () => import('@/views/retailers-management/retailers/retailers-view/RetailersView.vue'),
    meta: {
      resource: 'Retailers & Branches',
      action: 'V',
      pageTitle: 'View Retailer',
      breadcrumb: [
        {
          text: 'Retailers Management',
        },
        {
          text: 'Retailers & Branches',
        },
        {
          text: 'Retailers',
          active: true,
        },
      ],
    },
  },
]
