export default [
  // *===============================================---*
  // *--------- SALES TARGET ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/sales/targets/list',
    name: 'sales-targets-list',
    component: () => import('@/views/sales/sales-targets/sales-targets-list/SalesTargetsList.vue'),
    meta: {
      resource: 'Sales Targets',
      action: 'V',
      pageTitle: 'Sales Targets',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Targets',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales/targets/edit/:id',
    name: 'sales-targets-edit',
    component: () => import('@/views/sales/sales-targets/sales-targets-edit/SalesTargetsEdit.vue'),
    meta: {
      resource: 'Sales Targets',
      action: 'E',
      pageTitle: 'Edit Sales Target',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Targets',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sales/targets/create',
    name: 'sales-targets-create',
    component: () => import('@/views/sales/sales-targets/sales-targets-create/SalesTargetsCreate.vue'),
    meta: {
      resource: 'Sales Targets',
      action: 'C',
      pageTitle: 'Add Sales Target',
      breadcrumb: [
        {
          text: 'Sales',
        },
        {
          text: 'Sales Targets',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
]
