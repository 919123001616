export default [
  // *===============================================---*
  // *--------- Devices Marketplace ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/general-settings/devices-marketplace/list',
    name: 'general-settings-devices-marketplace-list',
    component: () => import('@/views/general-settings/devices-marketplace/devices-marketplace-list/DevicesMarketplaceList.vue'),
    meta: {
      resource: 'Devices Marketplace',
      action: 'V',
      pageTitle: 'Devices Marketplace',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Devices Marketplace',
        },
        {
          text: 'Devices',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/devices-marketplace/create',
    name: 'general-settings-devices-marketplace-create',
    component: () => import('@/views/general-settings/devices-marketplace/devices-marketplace-create/DevicesMarketplaceCreate.vue'),
    meta: {
      resource: 'Devices Marketplace',
      action: 'C',
      pageTitle: 'Add Device',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Devices Marketplace',
        },
        {
          text: 'Device',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/devices-marketplace/edit/:id',
    name: 'general-settings-devices-marketplace-edit',
    component: () => import('@/views/general-settings/devices-marketplace/devices-marketplace-edit/DevicesMarketplaceEdit.vue'),
    meta: {
      resource: 'Devices Marketplace',
      action: 'E',
      pageTitle: 'Edit Device',
      breadcrumb: [
        {
          text: 'General Settings',
        },
        {
          text: 'Devices Marketplace',
        },
        {
          text: 'Device',
          active: true,
        },
      ],
    },
  },
]
